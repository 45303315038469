import { ActivatedRouteSnapshot } from '@angular/router';

import { SignInMethod } from '@app/shared/enums';
import { IAction } from '@app/shared/models';

import { Credentials, CurrentUser, TokenResult } from '../models';

const ns = '[Auth]';

export class StateFailure {
    static readonly type = `${ns} State Failure`;
    constructor(public error: any) {}
}

export class UpdateReturnUrl {
    static readonly type = `${ns} Update Return Url`;
    constructor(public returnUrl: string | null) {}
}

export class ComputeThumbprint {
    static readonly type = `${ns} Compute Thumbprint`;
    constructor(public retry = 0) {}
}

export class ResetLoginState {
    static readonly type = `${ns} Reset Login State`;
    constructor(public signInMethod: SignInMethod) {}
}

export class LogoutConfirmation {
    static readonly type = `${ns} Logout Confirmation`;
}

export class Logout {
    static readonly type = `${ns} Logout`;
    constructor(public loginUrl?: string | null, public state?: ActivatedRouteSnapshot | undefined | null) {}
}
export class LogoutRedirect {
    static readonly type = `${ns} Logout Redirect`;
    constructor(public loginUrl?: string | null, public state?: ActivatedRouteSnapshot | undefined | null) {}
}

export class LoginRedirect {
    static readonly type = `${ns} Login Redirect`;
    constructor(public loginUrl: string | null) {}
}

export class Login {
    static readonly type = `${ns} Login`;
    constructor(public credentials: Credentials, public signInMethod: SignInMethod) {}
}

export class LoginSuccess {
    static readonly type = `${ns} Login Success`;
}

export class LoginFailure {
    static readonly type = `${ns} Login Failure`;
    constructor(public error: any) {}
}

export class LoadUser {
    static readonly type = `${ns} Load User`;
}
export class LoadUserSuccess {
    static readonly type = `${ns} Load User Success`;
    constructor(public user: CurrentUser) {}
}

export class LoadUserFailure {
    static readonly type = `${ns} Load User Failure`;
    constructor(public error: any) {}
}

export class UpdateCookie {
    static readonly type = `${ns} Update Cookie`;
}
export class UpdateCookieFailure {
    static readonly type = `${ns} Update Cookie Failure`;
    constructor(public error: any) {}
}

export class AddErrors {
    static readonly type = `${ns} Add Errors`;
    constructor(public action: IAction, public error: any) {}
}

export class ClearErrors {
    static readonly type = `${ns} Clear Errors`;
    constructor(public actions?: IAction | IAction[]) {}
}

export class SetAuthToken {
    static readonly type = `${ns} Update Token`;
    constructor(public token: TokenResult) {}
}
export class StateReady {
    static readonly type = `${ns} State Ready`;
}
export class NotAuthenticated {
    static readonly type = `${ns} Not Authenticated`;
    constructor(public method?: string | null) {}
}

export class RequestLogin {
    static readonly type = `${ns} Request Login`;
}

export class RequestLoginSuccess {
    static readonly type = `${ns} Request Login Success`;
}
export class UserAuthenticated {
    static readonly type = `${ns} User Authenticated`;
}

export class DenyiOSEntry {
    static readonly type = `${ns} Deny iOS entry`;
}
export class DenyIncognitoEntry {
    static readonly type = `${ns} Deny Incognito entry`;
    constructor(public result: { [key: string]: boolean }) {}
}
export class DenyBrowserEntry {
    static readonly type = `${ns} Deny Browser entry`;
    constructor(public browser: string) {}
}
export class DenyRequestDesktopEntry {
    static readonly type = `${ns} Deny Request Desktop entry`;
}

export class CookieTest {
    static readonly type = `${ns} Cookie Test`;
}

export class CookieTestSuccess {
    static readonly type = `${ns} Cookie Test Success`;
}
export class CookieTestFailure {
    static readonly type = `${ns} Cookie Test Failure`;
    constructor(public error: any) {}
}

export class VerifyRequest {
    static readonly type = `${ns} Verify Request`;
    constructor(public visitorId: string, public requestId: string) {}
}

export class VerifyRequestSuccess {
    static readonly type = `${ns} Verify Request Success`;
}

export class StartHeartBeat {
    static readonly type = `${ns} Start Heart Beat`;
    constructor(public interval: number) {}
}

export class SendDiagnosticData {
    static readonly type = `${ns} Send Diagnostic Data`;
}

export class ActivateSession {
    static readonly type = `${ns} Activate Session`;
    constructor(public trackLogin: boolean = false) {}
}

export class ActivateSessionSuccess {
    static readonly type = `${ns} Activate Session Success`;
}

export class ActivateSessionFailure {
    static readonly type = `${ns} Activate Session Failure`;
    constructor(public error: any) {}
}

export class RefreshToken {
    static readonly type = `${ns} Refresh Token`;
}