export enum RegistrationType {
    Webinar,
    Meeting,
}

export enum PaymentProvider {
    Paypal,
    Stripe,
}

export enum BlobContainer {
    Private,
    Public,
}

export enum MediaNodeType {
    Audio,
    Video,
    MediaAsset,
    Image,
}

export enum MiniPlayerState {
    Hidden,
    Mini,
    Full,
}

export enum NodeType {
    Node,
    Text,
    Html,
    Audio,
    Video,
    File,
}

export enum TimerUnit {
    Minutes,
    Hours,
    Days,
    Weeks,
    Months,
}

export enum SearchResultType {
    Node,
    Note,
}

export enum QuestionType {
    ShortAnswer,
    LongAnswer,
    Checkbox,
    Radio,
    Select,
    Date,
    Name,
    Email,
    Phone,
}

export enum NodeColours {
    Colour1 = 1,
    Colour2 = 2,
    Colour3 = 3,
    Colour4 = 4,
    Colour5 = 5,
    Colour6 = 6,
    Colour7 = 7,
    Colour8 = 8,
    Colour9 = 9,
    Colour10 = 10,
    Colour11 = 11,
    Colour12 = 12,
}

export enum NotificationType {
    Error,
    Info,
    Success,
    Acknowledge,
}

export enum EncodingProvider {
    Qencode,
}

export enum EncodingStatus {
    Unknown,
    Queued,
    Processing,
    Finished,
    Error,
    Cancelled,
}

export enum ErrorCodes {
    Generic = 0,
    DatabaseCreate = 1,
    DatabaseUpdate = 2,
    DatabaseDelete = 3,
    IndexUpdate = 4,

    // profile
    InvalidPassword = 100,
    TfaUnavailable = 101,
    TfaInvalidCode = 102,
    InvalidUser = 103,

    // manage users
    DuplicateEmails = 200,
    ExistingEmails = 201,
    OwnerRoleChange = 203,
    OwnerPromotion = 204,
    OwnerDemotion = 205,
    Crossline = 206,
    InvalidOrganizationId = 207,
    InvalidNodeId = 208,
    OwnerNodeChange = 209,
    ValidationError = 210,
    OwnerInvalidOrganizationId = 211,

    // not found
    AssetNotFound = 300,
    OrganizationNotFound = 301,
    UserNotFound = 302,
    NodeNotFound = 303,
    OwnerNotFound = 304,

    // login/recovery
    InvalidToken = 401,

    // home/nodes
    InvalidBinary = 500,
    AssetPublishing = 501,
    InvalidNodeIdForContent = 502,
    InvalidAssetState = 503,

    // tech
    NoConnection = 600,
    NormailizeAudio = 601,
    CookieTestFailed = 602,
    LocationServiceOffline = 603,
    InvalidAudience = 604,
    InvalidClaim = 605,
    InvalidVersion = 606,

    // links
    LinkNotFound = 700,
    LinkSessionLimit = 701,
    LinkExpired = 702,
    LinkHasNotStarted = 703,
    LinkSessionInactive = 704,
    LinkSubmissionExists = 705,
    LinkSubmissionInvalid = 706,
    LinkRequiredFieldMissing = 707,
    LinkFieldMustBeUnique = 708,
    LinkRegistrationEnded = 709,
    LinkSubmissionApproved = 710,
    LinkSubmissionPending = 711,
    LinkSessionMerged = 712,
    LinkSessionRegistrationPermitted = 713,
    LinkSessionActivated = 714,
    LinkSessionDeactivated = 715,
    LinkSubmissionAutoApproved = 716,
    LinkNoRegistration = 717,
    LinkOneTimeAccess = 718,
    LinkArchived = 719,
    LinkNotApproved = 720,
    LinkSessionInvalid = 721,
    LinkSessionReset = 722,
    LinkSessionInactivity = 723,
    LinkSessionTimeReset = 724,
    LinkSessionExtension = 725,
    LinkSessionNameChanged = 726,
    LinkSessionRequestMoreTime = 727,

    // notes
    NoteInvalidUserId = 800,
    NoteNotFound = 801,

    // submissions
    SubmissionInvalid = 900,
    SubmissionComplete = 901,
    SubmissionSubmitted = 902,
    SubmissionFormLogicInvalid = 903,
    SubmissionFinalized = 904,
    SectionNotFound = 905,
    SubmissionAutoSubmitted = 906,

    // zoom
    ZoomApiError = 1000,
    ZoomAddRegistrantError = 1001,
    ZoomTooManyRequestsAddRegistrant = 1002,
    ZoomWebinarNotFound = 1003,
    ZoomWebinarHasEnded = 1004,
    ZoomRegistrantAlreadyExists = 1005,
    ZoomWebinarMustBeFutureDate = 1006,
    ZoomWebinarUpdateFailed = 1007,
}

export enum WebinarType {
    Webinar = 5,
    RecurringWebinarWithoutFixedTime = 6,
    RecurringWebinarWithFixedTime = 9,
}

export enum MeetingType {
    Instant = 1,
    Scheduled = 2,
    RecurringNoFixedTime = 3,
    RecurringFixedTime = 8,
}

export enum VideoOption {
    None,
    Activation,
    StartTime,
}

export enum ScreenProfile {
    Unknown,
    ExtraSmall,
    Small,
    Medium,
    Large,
    ExtraLarge,
}

export enum ScreenOrientation {
    Unknown,
    Landscape,
    Portrait,
}

export enum ScreenMedia {
    Unknown,
    Print,
    Screen,
}

export enum NodeStatus {
    InActive,
    Active,
}

export enum TfaChallengeMethod {
    App = 1 << 0, // 1
    Email = 1 << 1, // 2
    // tslint:disable-next-line:no-magic-numbers
    Sms = 1 << 2, // 4
}

export enum SignInMethod {
    Form,
    Dialog,
}

export enum AccountRecoveryState {
    Default,
    Submitted,
    ResetPassword,
}

export enum UserStatus {
    Inactive,
    Active,
    Pending,
}

export enum Layout {
    Tree,
    List,
}

export enum UserRoles {
    None = 1 << 0, // 1
    User = 1 << 1, // 2
    // tslint:disable-next-line:no-magic-numbers
    OrganizationOwner = 1 << 2, // 4
    // tslint:disable-next-line:no-magic-numbers
    Administrator = 1 << 3, // 8
}

export enum NavigationState {
    Closed,
    Open,
}

export enum InsightEvents {
    Login = 'Login',
    Search = 'Search',
}

export enum ComponentLayout {
    Inline,
    Modal,
}

export enum MediaState {
    None = 0,
    Queued = 1,
    Scheduled = 2,
    Processing = 3,
    Finished = 4,
    Error = 5,
}

export enum MediaType {
    Audio,
    Video,
}

export enum MediaAssetType {
    Default,
    Inline,
}

export enum AddMedia {
    Default = 0,
    Record,
    Upload,
}

export enum NodePermission {
    None = 0,
    Readonly = 1,
    Manage = 2,
}

export enum DetailPanel {
    None = 0,
    UserPermissions = 1,
    BookMeeting = 2,
}

export enum QueueItemState {
    Cancelling,
    Pending,
    Validating,
    ValidationComplete,
    SavingNode,
    SavingNodeComplete,
    SavingBinary,
    SavingBinaryComplete,
    SavingAsset,
    SavingAssetComplete,
    SavingContent,
    SavingContentComplete,
    SavingContentSnapshot,
    SavingContentSnapshotComplete,

    SavingMediaAsset,
    SavingMediaAssetComplete,

    ProcessingAssetForNode,
    ProcessingAssetForNodeComplete,

    ProcessingMediaAsset,
    ProcessingAssetComplete,

    Finished,
}

export enum LinkType {
    Shareable,
    Form,
    Event,
    Checklist,
    ChecklistLink,
    Preamble,
}

export enum LinkExpiration {
    None,
    Date,
    Timer,
}

export enum LinkInactivityMode {
    Default,
    User,
    Link,
}

export enum LinkStatus {
    Inactive,
    Active,
}

export enum RegistrationMode {
    Default,
    Daily,
    Weekly,
    Monthly,
    Onetime,
}

export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export enum SessionStatus {
    Inactive,
    Active,
    Failed,
    Pending,
}

export enum AdminSessionStatus {
    None = 0,
    Disabled, // greyed out, strike-through, disabled
    Inactive, // red
    Active, // green
    ActionRequired, // yellow
    Reset, // purple
    Blocked
}

export enum BlobType {
    Unspecified = 0,
    Page = 1,
    Block = 2,
    // Append = 3,
}

export enum FilterLinkTypes {
    Registration,
    Communal,
    Timer,
    StartEnd,
    NotActivated,
    Active,
    Overdue,
    RequestedLinks,
    UserTimer,
    LinkTimer,
}

export enum FilterSessionTypes {
    All,
    Triage,
    ActiveAndApproved,
    RequiresApproval,
    AllowRegistration,
    RegistrationPending,
}

export enum LinkSessionsGroupBy {
    Sessions,
    FormSubmissions,
}

export enum WorkStatus {
    Ready = 0,
    Working = 1,
    Done = 2,
    Error = 3,
}

export enum FormSectionType {
    Page = 0,
    Text = 1,
    Question = 2,
    Media = 3,
    File = 4,
    Event = 5, // event payment
    Summary = 6, // payment summary
    Booking = 7,
    WebinarRegistration = 8,
    MeetingRegistration = 9,
}

export enum FormLinkType {
    Form,
    Poll,
    Booking,
}

export enum FormQuestionType {
    ShortAnswer = 0,
    LongAnswer = 1,
    MultipleChoice = 2,
    Checkbox = 3,
    Dropdown = 4,
    Scale = 5,
    Date = 6,
    Time = 7,
    DateTime = 8,
    Matrix = 9,
    MatrixCheckbox = 10,
}

export enum Audience {
    None = 0,
    Admin = 4,
    ShareableLinks = 8,
    Forms = 16,
    LinkRequest = 32,
    Events = 64,
    Checklists = 128,
}

export enum PublicAccessMode {
    ViewOnly = 0,
    ViewAndRegister,
}

export enum LoginFormType {
    Default,
    Influencer,
}

export enum PaymentStatus {
    None,
    Cancelled,
    Pending,
    Complete,
    Refunded,
    Waiting
}

export enum PaymentMethod {
    Cash,
    Paypal,
    Stripe,
}
