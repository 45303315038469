import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AuthActions, CoreActions } from '@app/data/actions';
import { AuthState, DebugState } from '@app/data/state';
import { Store } from '@ngxs/store';
import { map, of } from 'rxjs';

@Component({
    selector: 'data-diagnosis',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./diagnosis.component.html`,
    styleUrls: [`./diagnosis.component.scss`],
})
export class DiagnosisComponent {
    isDiagnosisEnabled$ = this.store.select(DebugState.isEnabled());
    getDiagnosticResult$ = this.store.select(AuthState.getDiagnosticResult());
    supportLink$ = of(this.sanitizer.bypassSecurityTrustUrl('#'));

    dataSent = false;

    constructor(private store: Store, private sanitizer: DomSanitizer) {}

    onSendDiagnosisData() {
        this.store.dispatch(new AuthActions.SendDiagnosticData());
        this.dataSent = true;
    }

    onCopyToClipboard(text: string) {
        this.store.dispatch(
            new CoreActions.CopyToClipboard({
                text: `${text}`,
                message: 'Successfully copied to clipboard',
            }),
        );
    }
}
